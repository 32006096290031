// Get the URL Params
const getParams = query => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

//check key validity in url_param array. Should return true if key is "truthy"
const isPresent = (input, params) => {
  return input in params && params[input];
};
//check if value is numeric. Used for aff_ids and offer_ids
const isNumeric = input => {
  return parseInt(input) - 0 == parseInt(input);
};

/*
  function localStorageTest
  tests if localStorage is available to us.

  @params: None
  @return boolean     True if localstorage is available. False otherwise.
*/

// function to test if localstorage is available to us
function localstorageTest() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function multipleNetworkCheck() {
  if (Object.keys(window.tdl.domain).length > 1) {
    return true;
  } else {
    return false;
  }
}
function formatDomainList() {
  // if its just  single brand string , convert to object
  if (typeof window.tdl.domain == "string") {
    window.tdl.domain = { 'default': window.tdl.domain };
  } else {
    //just let it be a normal
  }
}

// function to test if cookies are available to us
function testCookie() {
  document.cookie = "cookie_test=test";
  let available = document.cookie.indexOf("cookie_test=") !== -1;
  //delete cookie
  document.cookie= "cookie_test=;max-age=0";
  return available;
}

function testCookieDomain(domain) {
  document.cookie = "tunesdktest=test; domain=" + domain + "; max-age=60; path=/;";
  let setSuccessfully = document.cookie.indexOf("tunesdktest=") !== -1;
  document.cookie = "tunesdktest=;max-age=0;";
  return setSuccessfully
}

//function to set first party cookie for network specific transaction id in the current domain
function setCookie(name, value, hostname, days) {
  try {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    let domain = "";
    if (hostname) {
      domain = "; domain=."+hostname;
    }
    document.cookie = name + "=" + (value || "") + domain  + expires + "; path=/;";
    return true;
  } catch (e) {
    return false;
  }
}

//function to retrieve cookie 
function getCookie(name, exact) {
  let values = [];
  const cookies = decodeURIComponent(document.cookie).split(';');
  for(let i=0; i < cookies.length; i++) {
      let cPair = cookies[i].split('=');
      // if the key matches exactly then add the value
      // of if we are not looking for an exact match and it contains the key, then add the value
      if (cPair[0].trim() == name || (exact === false && cPair[0].trim().includes(name))) values.push(cPair[1].trim());
  }
  return values;
}

//function to access local storage
function getLocalStorage(name, exact) {
  let values = [];
  if (exact === true) {
    let val = window.localStorage.getItem(name);
    if (val) values.push(val);
  } else {
    Object.keys(localStorage).filter(key => key.includes(name)).forEach(key => values.push(localStorage.getItem(key)));
  }
  return values;
}

export { getParams, isPresent, isNumeric, localstorageTest, multipleNetworkCheck, formatDomainList, setCookie, getCookie, testCookie, testCookieDomain, getLocalStorage };
