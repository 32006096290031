import "regenerator-runtime/runtime.js";
import {
  getParams,
  isPresent,
  isNumeric,
  localstorageTest,
  multipleNetworkCheck,
  formatDomainList,
  setCookie,
  getCookie,
  testCookie,
  testCookieDomain,
  getLocalStorage
} from "./utils.js";

var Tune_SDK_Version = process.env.npm_package_version + '.' + process.env.CI_PIPELINE_ID;

tdl.identify = identify;
tdl.convert = convert;

//checks to see if a string or object was passed, formats domain list to be object
formatDomainList()

while (window.tdl.length) {
  if(!tdl.domain) {
    break;
  }

  let funct = window.tdl.shift();
  let fn = funct[0]
  let args = funct.slice(1)
  let resolve = funct[2]
  let reject = funct[3]

  if(!resolve)
    resolve = () => {};

  if(!reject)
    reject = () => {};

    switch (fn) {
      case "identify":
        identify(...args).then(resolve).catch(reject)
        break;
      case "convert":
        convert(...args).then(resolve).catch(reject)
        break;
    }
}

async function convert(options = {}) {
  let promises = [];
  
  // get param package from args and url string
  var params = {
      ...options,
      ...getParams(window.location.search),
  };

  for (const domain in window.tdl.domain) {
    let transaction_ids = [];
    const address = getStorageAddress(options, domain);
    // if they passed offer in convert options, only convert transaction_ids with the offer id in the address
    const exact = address !== 'tdl_'+domain;

    const tid_cookies = getCookie(address, exact);
    // check cookies first
    if (tid_cookies.length > 0) {
      transaction_ids = tid_cookies;
    } 
    // if we aren't looking for a specific offer or we couldn't find anything in cookies we should check localstorage
    if (!exact || transaction_ids.length == 0) {
      const tid_ls = getLocalStorage(address, exact);
      if (tid_ls.length > 0) {
        transaction_ids = transaction_ids.concat(tid_ls);
      }
    } 

    // check if goal is required
    if (("goal_id" in options) || ("goal_ref" in options) || ("goal_name" in options)) {
      var tdl_url = window.tdl.domain[domain] + "/aff_goal?a=lsr&";
    } else {
      var tdl_url = window.tdl.domain[domain] + "/aff_lsr?";
    }

    // promo code conversion does not need transaction_id. 
    // if both promo_code and transaction_id exists for this domain, send both
    // along with all other options and let tracking engine validate and make decision.
    promises.concat(tidConvert(tdl_url, transaction_ids, options));
    if (promises.length == 0) 
      promises.concat(promocodeConvert(tdl_url, options));

    // if no promise generated, transaction_id is missing and promo code is not available,
    // conversion is unattributable. ping tracking engine to emit metric
    if (promises.length == 0) {
      promises.concat(generateUnattributable(window.tdl.domain[domain], params))
    }
  }

  return Promise.allSettled(promises);
}

// function to attempt conversion with transaction_ids
function tidConvert(tdl_url, transaction_ids, options) {
  let conversions = [];
  let promises = [];
  if (transaction_ids.length > 0) {
    let url_params = makeCommonQueryString(options);
    // if we have at least one transaction id, iterate through and add to url
    for (let tid of transaction_ids) {
      let and = url_params.length > 0 ? "&" : "";
      conversions.push(`${tdl_url}transaction_id=${tid}${and}${url_params}`);
    }
    for (let url of conversions) {
      promises.push(sendRequest(url));
    }
  }
  // this will be empty if we didn't have transaction_ids
  return promises;
}

// function to attempt conversion with just promo code if no transaction_ids found
function promocodeConvert(tdl_url, options) {
  if (isPresent("promo_code", options)) {
    let url_params = makeCommonQueryString(options);
    return [sendRequest(`${tdl_url}${url_params}`)];
  } else return [];
}

      
// function to make substring of common url parameters to add to conversion urls
function makeCommonQueryString(options, doExcludes=true) {
  let url_params = '';
  // these params are unecessary (and could cause issues) in the tracking engine with transaction_id
  let excludes = new Set(["offer_ref", "offer_id"]);

  for (const p in options) {
    if (doExcludes && excludes.has(p)) {
      continue;
    }
    if (!isPresent(p, options)){
      continue;
    }
    let and = url_params.length > 0 ? "&" : "";
    let key = encodeURIComponent(p);
    let val = encodeURIComponent(options[p]);
    url_params += `${and}${key}=${val}`;
  }
  return url_params;
}

function sendRequest(url) {
  const encoded_url = encodeURI(url);
  return fetch(encoded_url, {
    mode: "cors",
    headers: {
      "Tune-SDK-Version": Tune_SDK_Version,
    }
  });
}

// ping tracking engine for metrics collection
function generateUnattributable(domain, options) {
  domain = domain.endsWith('/') ? domain.slice(0, -1) : domain;  
  let url = `${domain}/ping`;

  // send all params from url with ping
  let url_params = makeCommonQueryString(options, false);
  url = url_params === '' ? `${url}` : `${url}?${url_params}`

  return [sendRequest(url)]
}

/*
Identify function
This is a more complex function.
It either stores the session or Creates and then stores the session.
It looks for the session in a URL param and in a specified variable

@params: None
@returns: None
*/
async function identify(defaults = {}, override = {}) {

  //check if more than network was passed when init'd
  var multiple_networks = multipleNetworkCheck()

  // get param package from defaults, url string and override
  var params = {
    ...defaults,
    ...getParams(window.location.search),
    ...override
  };

  // Determine network id to associate the click with
  if (
    multiple_networks &&
    isPresent("network_id", params) &&
    isPresent(params["network_id"], window.tdl.domain)
  ) {
    var network = params["network_id"];
  } else if (!multiple_networks) {
    var network = Object.keys(window.tdl.domain)[0];
  } else {
    // nothing, there are multiple networks and none of them were declared
    return;
  }

  const address = getStorageAddress(params, network);
  // Check to see if session storage has been set for the address and assemble parameter list
  if (window.sessionStorage.getItem(address) === null) {
    // Case one: Transaction id has been passed, store transid
    if (isPresent("transaction_id", params)) {
      storeSession(params["transaction_id"], address);
    }
    
    // Case two: Transaction ID missing. affiliate id and offer id are present. Create a session based on the affiliate and offer.
    else if (
      ((isPresent("aff_id", params) && isNumeric(params.aff_id)) ||
        isPresent("aff_ref", params)) &&
      ((isPresent("offer_id", params) && isNumeric(params.offer_id)) ||
        isPresent("offer_ref", params)) &&
      !("transaction_id" in params)
    ) {
      
      var session = await createSession(params, network);
      if (session) storeSession(session, address);
    }

    // Case three: not enough information passed
    else {
      return;
    }
  } else {
    // session has already been started, do nothing.
    return;
  }
}

/*
  Function createSession.
  Creates a session in HasOffers by generating a click.

  @param Map         url_params            The query string parameters parsed from the current page url
  @param String      network      The Hasoffers tracking domain to use for the event.

  @return String     transaction_id generated by click.
*/
async function createSession(url_params, network) {
  let tdl_url = window.tdl.domain[network] + "/aff_c?";
  // loop the param list and attach params to the click url
  for (const p in url_params) {
    if (isPresent(p, url_params)) {
      tdl_url = tdl_url + "&" + p + "=" + url_params[p];
    }
  }
  tdl_url += "&format=json";

  //attempt to generate click, error out if failure
  const request = async () => {
    const encoded_url = encodeURI(tdl_url);
    const resp = await fetch(encoded_url, {
      mode: "cors",
      headers: {
        "Tune-SDK-Version": Tune_SDK_Version,
      }
    });
    const json = await resp.json();
    const trans_id = json.response.data.transaction_id;
    return trans_id;
  };

  const tid = await request();
  return await tid;
}

/*
  function storeSession
  acts as a simple interface for local and remote storage 

  @param     String      transaction_id
  @param     String      address=''          location of stored transaction_id  
*/
function storeSession(transaction_id, address) {
  // If unable to set a cookie, set the value in localStorage as a fallback.
  if (testCookie() === true) {
    cookieStore(transaction_id, address);
  } else {
    localStore(transaction_id, address);
  }
}

/*
  function localStore
  acts as a simple interface for local and remote storage


  @param     String      transaction_id
  @param     String      address=''          location of stored transaction_id 
*/
//store session in local storage
function localStore(transaction_id, address) {
  try {
    // Use sessionStorage to keep track of sessions that have already been identified
    window.sessionStorage.setItem(address, transaction_id.toString());
    window.localStorage.setItem(address, transaction_id.toString());
  } catch (e) {
    console.error(e);
  }
}

/*
  function cookieStore
  function to get the top level domain and set the first-party cookie

  @param     String      transaction_id
  @param     String      address=''         location of stored transaction_id  
*/
// set the cookie domain to the top level domain and it will be available in all subdomains 
function cookieStore(transaction_id, address) {
  //use session storage to keep track of sessions we have already identified
  try {
    window.sessionStorage.setItem(address, transaction_id.toString());
  } catch (e) {
    console.error(e);
  }

  // Attempt to set the cookie assuming a 1-part TLD, then 2-part TLD, etc. Check each time
  // If the cookie was successfully set and if not, keep expanding the domain on which the
  // cookie gets set.
  // The reasoning: we want to set a cookie on the root of the owned domain. This might be
  // something like "blah.com" or "blah.net", in which case the first hostname portion that
  // we try below would work. However, TLDs are not always 1-part like ".com" but could
  // be 2+ parts e.g. ".com.au". If that's the case, then the root of the domain on which to
  // set the cookie is "blah.com.au", and the browser would reject an attempt to set on ".com.au".
  let hostnames = window.location.hostname.split('.');
  let domain = ""
  for (let i=hostnames.length-2; i>=0; i--) {
    let testDomain = hostnames.slice(i, hostnames.length).join('.')
    console.log('testing domain: ', testDomain)
    if (testCookieDomain(testDomain)) {
      domain = testDomain
      console.log('found domain: ', domain)
      break
    }
  }
  if (domain !== "") {
    return setCookie(address, transaction_id, domain, 5*365);
  }
  return false
}

/*
  function getStorageAddress
  function to get the offer reference from the url parameters to use in storage identifier

  @param     object      all_params      Package of defaults, url string and override parameters
  @param     String      network         Network id to use for storage 
  @return    String                      Address for stored transaction_id including the network and offer if available
*/
// 
function getStorageAddress(all_params, network) {
  let offer = "";
  if (isPresent("offer_id", all_params) && isNumeric(all_params.offer_id)) {
    offer = "_"+all_params.offer_id;
  } else if (isPresent("offer_ref", all_params)) {
    offer = "_"+all_params.offer_ref;
  }
  return "tdl_" + network + offer;
}
